<template>
    <div class="header-section">
        <template v-for="(item, i) in slice.items" :key="`slice-item-${i}`">
            <div
                v-if="shoudDisplay(item)"
                class="header-link"
                :class="{
                    'header-link--has-tag': item.tag,
                    'header-link--strategy': item.theme === 'strategy' || (item.tag && (!item.theme || item.theme === 'malt')),
                    'header-link--ai': item.theme === 'ai',
                }"
            >
                <NuxtLink v-if="item.linkUrl" v-slot="{href, navigate}" custom :to="getHref(item.linkUrl)">
                    <div class="header-link__wrapper" @click="navigate">
                        <div v-if="item.tag" class="header-link__tag">{{ item.tag }}</div>
                        <a :href="href">{{ item.linkText }}</a>
                    </div>
                </NuxtLink>
                <PrismicLink v-else-if="item.link" :field="item.link" :link-resolver="linkResolver">{{ item.linkText }}</PrismicLink>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
    import {getSliceComponentProps, PrismicLink} from '@prismicio/vue';
    import type {HeaderLinksSliceSlice, HeaderLinksSliceSliceDefaultItem} from '@prismicio-types';
    import {linkResolver} from '@navbar-unlogged/helpers/link-resolver';
    import {useABTestStore} from '@navbar-unlogged/store/abtest-unlogged.store';
    import {guestHost} from '#imports';

    defineProps(getSliceComponentProps<HeaderLinksSliceSlice>());

    const getHref = (url: string) => `${guestHost()}${url}`;

    const {getVariation} = useABTestStore();

    function shoudDisplay(item: HeaderLinksSliceSliceDefaultItem) {
        if (!item.abTest) return true;
        const [campaignId, variationId] = item.abTest.split('.');
        return getVariation(campaignId) === variationId;
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    .header-section {
        @include vars.flex-row-align-center;
        gap: 12px;
        justify-items: space-around;
        align-content: center;

        .header-link {
            font-size: var(--joy-font-size-primary-300);
            padding-bottom: 2px;
            user-select: none;
            cursor: pointer;

            a {
                display: block;
                text-decoration: none;
                color: var(--joy-color-neutral-50);
                &:hover {
                    color: var(--joy-color-secondary-50);
                }
            }
            &__wrapper {
                flex: 1 1 auto;
            }

            &__tag {
                position: absolute;
                border-radius: var(--joy-core-radius-3);
                padding: 0 9px 1px;
                font-size: 10px;
                top: -10px;
                line-height: 1em;
                font-weight: var(--joy-font-weight-bold);
                color: white;
            }

            &--has-tag {
                position: relative;
                a {
                    font-weight: var(--joy-font-weight-bold);
                }
            }

            &--strategy {
                a {
                    color: var(--joy-color-secondary-30);
                }
                .header-link__tag {
                    background: var(--joy-color-tertiary-50);
                }
            }

            &--ai {
                a {
                    color: var(--joy-color-ai-primary-30);
                    &:hover {
                        color: var(--joy-color-ai-primary-70);
                    }
                }

                .header-link__tag {
                    background: linear-gradient(180deg, rgb(144, 53, 162) 0%, rgba(252, 87, 87, 0.85) 100%);
                }
            }
        }

        @include mq.screen_md {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;

            .header-link {
                border-top: solid 1px var(--joy-color-neutral-30);
                font-size: var(--joy-font-size-primary-600);
                display: flex;
                text-align: left;
                width: 100%;

                a {
                    width: 100%;
                    margin: var(--joy-core-spacing-8) 0;
                }

                &__tag {
                    top: 21px;
                }
            }
        }
    }
</style>
